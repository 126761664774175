body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.scrollable-content {
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Zmienne dla trybu ciemnego */
[data-bs-theme="dark"] {
  --bs-body-bg: #212529;
  --bs-body-color: #f8f9fa;
  --bs-primary-bg-subtle: #0d6efd20;
  --bs-secondary-bg-subtle: #6c757d20;
  --bs-border-color: #495057;
  --bs-btn-bg: #343a40;
  --bs-form-select-bg: #343a40;
  --bs-form-select-color: #f8f9fa;
}

/* Zmienne dla trybu jasnego */
[data-bs-theme="light"] {
  --bs-body-bg: #ffffff;
  --bs-body-color: #212529;
  --bs-primary-bg-subtle: #0d6efd10;
  --bs-secondary-bg-subtle: #6c757d10;
  --bs-border-color: #dee2e6;
}

/* Płynne przejścia między motywami */
body {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.bg-body-tertiary {
  transition: background-color 0.3s ease;
}

/* Style dla trybu ciemnego */
[data-bs-theme="dark"] .btn-light {
  background-color: #343a40;
  border-color: #495057;
  color: #f8f9fa;
}

[data-bs-theme="dark"] .btn-light:hover {
  background-color: #495057;
  border-color: #6c757d;
  color: #f8f9fa;
}

[data-bs-theme="dark"] .form-select {
  background-color: #343a40;
  color: #f8f9fa;
  border-color: #495057;
}

[data-bs-theme="dark"] .bg-body-tertiary {
  background-color: #2c3034 !important;
}

/* Style dla modali w trybie ciemnym */
[data-bs-theme="dark"] .modal-content {
  background-color: #343a40;
  color: #f8f9fa;
}

[data-bs-theme="dark"] .modal-header {
  border-bottom-color: #495057;
}

[data-bs-theme="dark"] .modal-footer {
  border-top-color: #495057;
}

/* Style dla alertów w trybie ciemnym */
[data-bs-theme="dark"] .alert-danger {
  background-color: #dc3545;
  color: #fff;
}

/* Stylowanie scrollbarów dla trybu ciemnego */
[data-bs-theme="dark"] ::-webkit-scrollbar {
  width: 10px;
}

[data-bs-theme="dark"] ::-webkit-scrollbar-track {
  background: #2c3034;
}

[data-bs-theme="dark"] ::-webkit-scrollbar-thumb {
  background: #495057;
  border-radius: 5px;
}

[data-bs-theme="dark"] ::-webkit-scrollbar-thumb:hover {
  background: #6c757d;
}

/* dla modala organizacji */
.modal-90w {
  min-width: 90%;
  max-width: 90vw;
}

.modal-shorter .modal-content {
  max-height: 80vh;
}
.modal-90w {
  width: 90%;
  max-width: 90%;
}

.modal-90w {
  width: 90%;
  max-width: 90%;
}

.modal-90w .modal-content {
  height: 90vh;
}

/* Styl dla nagłówka tabeli */
.admin-data-table-header {
  padding: 0.5rem 1rem;
  margin: 0;
}

/* Styl dla kontenera tabeli */
.table-container {
  flex: 1;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.table-responsive {
  margin: 0;
  padding: 0;
  height: 100%;
}

.table {
  margin: 0;
}
